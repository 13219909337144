import { Property } from '@propertypal/shared/src/types/property';
import { getGalleryImages, getMainImage } from '@propertypal/shared/src/utils/property';
import React, { FunctionComponent } from 'react';
import { Lazy, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyImage from '../image/LazyImage';
import { SliderImage } from './PropertyBoxSlider.style';

interface Props {
  property: Property;
  imageLocator?: string;
  useSlider?: boolean;
  loading?: 'lazy' | 'eager';
}

const PropertyBoxSlider: FunctionComponent<Props> = (props) => {
  const [heroImage] = getMainImage(props.property, props.imageLocator);
  const images = getGalleryImages(props.property, props.imageLocator || '');

  if (images.length <= 1 || !props.useSlider) {
    return <LazyImage src={heroImage} width={880} height={645} alt={`${props.property.displayAddress} photo`} />;
  }

  return (
    <Swiper
      style={{ height: '100%' }}
      modules={[Lazy, Pagination, Navigation]}
      lazy={{ checkInView: true }}
      loop
      pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 5 }}
      navigation
    >
      {images.map((image, index) => (
        <SwiperSlide key={image.key}>
          <SliderImage
            src={image.image}
            width={880}
            height={645}
            loading={index === 0 ? props.loading : 'lazy'}
            alt={props.property.displayAddress}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PropertyBoxSlider;
