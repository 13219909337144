import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% { 
    transform: scale(1); 
  }
  33% { 
    transform: scale(0.75);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const Container = styled.button<{ $showBackground?: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ theme, $showBackground }) => ($showBackground ? theme.primaryTranslucent : 'transparent')};
  border-radius: 30px;
  color: ${({ theme }) => theme.textDark};

  span {
    font-size: 16px;
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

export const FavouriteBox = styled.div<{ playState: 'paused' | 'running' }>`
  position: relative;
  animation-name: ${pulse};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-play-state: ${(props) => props.playState};
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

export const Item = styled.div<{ opacity: number; size: number }>`
  position: absolute;
  top: calc(50% - ${({ size }) => size / 2}px);
  left: calc(50% - ${({ size }) => size / 2}px);
  opacity: ${(props) => props.opacity};
  transition: 1s opacity;

  svg {
    display: block !important;
  }
`;
