import { faBath } from '@fortawesome/pro-light-svg-icons/faBath';
import { faBedAlt } from '@fortawesome/pro-light-svg-icons/faBedAlt';
import { faCouch } from '@fortawesome/pro-light-svg-icons/faCouch';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import noPhoto from '@propertypal/shared/src/resources/fallbacks/no-photo-icon.png';
import { Property } from '@propertypal/shared/src/types/property';
import { PropertySearchResult } from '@propertypal/shared/src/types/propertySearch';
import { formatNumber } from '@propertypal/shared/src/utils/floatToCurrency';
import platform from '@propertypal/shared/src/utils/platform';
import { getMainImage, getAgentLogos } from '@propertypal/shared/src/utils/property';
import getSellingAgent from '@propertypal/shared/src/utils/property/getSellingAgent';
import { SubHeading, Text } from '@propertypal/web-ui/src/typography';
import React, { FunctionComponent, MouseEventHandler } from 'react';
import { useTheme } from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import PropertyPrice from '../property/PropertyPrice';
import StartEndTime from '../time/StartEndTime';
import EnergyRatingIcon from './EnergyRatingIcon';
import OldPropertyBox from './OldPropertyBox';
import OpenViewingOverlay from './OpenViewingOverlay';
import { SummaryRow } from './PremierBox.style';
import PropertyBadge from './PropertyBadge';
import PropertyBanner from './PropertyBanner';
import {
  AccountLogo,
  AccountLogos,
  BottomRow,
  ContactText,
  Container,
  Content,
  DeveloperLogos,
  DevelopmentBoxFiller,
  HeroNoImage,
  HiddenAddress,
  HiddenContainer,
  ImageContent,
  LinkContainer,
  OptionsRow,
  SellingAgentLogos,
  TextBox,
  TextBoxBottom,
  TextBoxTop,
  ViewsPill,
} from './PropertyBox.style';
import PropertySliderBox from './PropertyBoxSlider';
import PropertyDistance from './PropertyDistance';
import PropertyFavourite from './PropertyFavourite';
import PropertyHide from './PropertyHide';
import ShowHomesBox from './ShowHomesBox';

interface Props {
  property: Property;
  onFavourite?: () => (favourited: boolean, propertyId: number) => Promise<boolean>;
  onHideToggle?: () => (hidden: boolean, propertyId: number) => Promise<boolean>;
  onEmailClick?: (property: Property) => void;
  onCallClick?: (property: Property) => void;
  overlay?: React.ReactElement;
  locationDetail?: PropertySearchResult['locationDetail'];
  imageLocator?: string;
  showAuctionsLabel?: boolean;
  showOpenViewingLabel?: boolean;
  useWideDisplay?: boolean;
  useSlider?: boolean;
  loading?: 'lazy' | 'eager';
  disableWideMode?: boolean;
  onClick?: () => void;
  hideBriefText?: boolean;
  useEnhancedBranding?: boolean;
  hideBadge?: boolean;
}

const PropertyBox: FunctionComponent<Props> = (props) => {
  const { property, overlay } = props;
  const useWideDisplay = props.useWideDisplay && !props.disableWideMode;
  const { srpBrandingBgColour } =
    (props.useEnhancedBranding && props.property.enhancedBranding && props.property.enhancedBrandingColours) || {};
  const theme = useTheme();
  const [heroImage] = getMainImage(property, props.imageLocator);
  const accountLogos = getAgentLogos(property, 'STANDARD');
  const showAuctionLabel = props.showAuctionsLabel && property.auctionTime;
  const showOpenViewingLabel = props.showOpenViewingLabel && property.openViewing;
  const showOpenViewingOverlay = !showOpenViewingLabel && property.openViewing && !property.openViewing.past;
  const canHide = props.onHideToggle && property.type === 'property';
  const canFavourite = props.onFavourite && property.type === 'property';
  const sellingAgent = getSellingAgent(property);
  const canEmail =
    props.onEmailClick && props.property.enhancedBranding && (sellingAgent?.emailEnabled || sellingAgent?.email);
  const canCall = props.onCallClick && props.property.enhancedBranding;
  const hasDeveloperLogo = property.account.developer && property.account.logo && !property.account.privateDeveloper;
  const isDevelopment = property.development || property.type === 'development';

  if (platform.type === 'propertypal' && !process?.env?.JEST_WORKER_ID) {
    return <OldPropertyBox {...props} />;
  }

  const handleEmailClick: MouseEventHandler = (e) => {
    if (props.onEmailClick) {
      e.preventDefault();
      props.onEmailClick(property);
    }
  };

  const handleCallClick: MouseEventHandler = (e) => {
    if (props.onCallClick) {
      e.preventDefault();
      props.onCallClick(property);
    }
  };

  if (canHide && property.hidden) {
    return (
      <Container
        className="pp-property-box pp-property-box-hidden"
        $useWideDisplay={useWideDisplay}
        data-testid="property-box-hidden"
      >
        <HiddenContainer $useWideDisplay={useWideDisplay}>
          <HiddenAddress>{property.displayAddress.replace(/\s+/g, ' ')}</HiddenAddress>
          <PropertyHide
            hidden={property.hidden}
            onHideToggle={(hidden) => props.onHideToggle!()(hidden, property.id)}
            key={`hidden-${property.id}`}
            button
          />
        </HiddenContainer>
      </Container>
    );
  }

  return (
    <Container className="pp-property-box" $useWideDisplay={useWideDisplay}>
      {showAuctionLabel && property.listingTime?.type === 'auction' && property.listingTime.timeText && (
        <StartEndTime text={property.listingTime.timeText} />
      )}

      {showOpenViewingLabel && property.openViewing?.text && <StartEndTime text={property.openViewing.text} />}

      {!!props.property.distance && (
        <PropertyDistance distance={props.property.distance} locationDetail={props.locationDetail} />
      )}

      {overlay}

      <LinkContainer
        href={property.path}
        prefetch={false}
        onClick={props.onClick}
        $borderColor={srpBrandingBgColour}
        $useWideDisplay={useWideDisplay}
      >
        <Content $useWideDisplay={useWideDisplay}>
          <ImageContent $useWideDisplay={useWideDisplay}>
            {!heroImage && <HeroNoImage src={noPhoto.src} alt="No photo available" />}

            {!!heroImage && (
              <PropertySliderBox
                property={property}
                imageLocator={props.imageLocator}
                useSlider={props.useSlider}
                loading={props.loading}
              />
            )}

            <PropertyBanner property={property} />

            {!showOpenViewingOverlay && !!property.viewsInLast7Days && (
              <ViewsPill>
                <b>{formatNumber(property.viewsInLast7Days)}</b> views this week
              </ViewsPill>
            )}

            {showOpenViewingOverlay && property.openViewing && (
              <OpenViewingOverlay
                openViewing={property.openViewing}
                themeName="dark"
                containerStyle={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              />
            )}
          </ImageContent>

          <TextBox>
            <TextBoxTop $useWideDisplay={useWideDisplay} $isDevelopment={accountLogos.length > 1}>
              {!props.hideBadge && (
                <PropertyBadge
                  property={property}
                  showAuctionTime={!showAuctionLabel}
                  useWideDisplay={props.useWideDisplay}
                />
              )}

              {platform.select(
                null,
                <Text
                  color={theme.textMid}
                  fontSize={21}
                  mb={12}
                  data-testid="property-box-address"
                  fontWeight={platform.select('bold', 'normal')}
                >
                  {property.displayAddressLine1 ? <b>{property.displayAddressLine1},</b> : ''}{' '}
                  {property.displayAddressLine2}
                </Text>,
              )}

              <PropertyPrice
                containerStyle={{ marginBottom: 12, fontWeight: 600 }}
                price={property.price}
                alternativeCurrencyPrice={property.alternativeCurrencyPrice}
                fontSize={34}
                status={property.status}
                comingSoonText={property.comingSoonText}
                primaryColor={theme.textDark}
                prefixBreak
              />

              {platform.title === 'Propertynews' && (
                <SummaryRow>
                  {!!property.numBedrooms && (
                    <>
                      <FontAwesomeIcon icon={faBedAlt} color={theme.textDark} size={22} />
                      <Text color={theme.textLight}>{property.numBedrooms}</Text>
                    </>
                  )}

                  {!!property.numBathrooms && (
                    <>
                      <FontAwesomeIcon icon={faBath} color={theme.textDark} size={22} />
                      <Text color={theme.textLight}>{property.numBathrooms}</Text>
                    </>
                  )}

                  {!!property.numReceptionRooms && (
                    <>
                      <FontAwesomeIcon icon={faCouch} color={theme.textDark} size={22} />
                      <Text color={theme.textLight}>{property.numReceptionRooms}</Text>
                    </>
                  )}

                  {property.ber && <EnergyRatingIcon property={property} hideEpcText />}
                </SummaryRow>
              )}

              {platform.select(
                <SubHeading
                  color={theme.textMid}
                  fontSize={16}
                  mb={12}
                  fontWeight="600"
                  data-testid="property-box-address"
                >
                  {property.displayAddress.replace(/\s+/g, ' ')}
                </SubHeading>,
                null,
              )}

              {!!props.property.briefText && !props.hideBriefText && (
                <Text fontSize={14} color={theme.textLight} mt={10} mb={10}>
                  {props.property.briefText}
                </Text>
              )}

              {property.ber && <EnergyRatingIcon property={property} hideEpcText />}

              <ShowHomesBox property={property} fontSize={14} />
            </TextBoxTop>

            {(canCall || canEmail) && (
              <TextBoxBottom>
                <ContactText>Contact {sellingAgent?.organisation}</ContactText>

                <BottomRow>
                  {canCall && (
                    <button type="button" onClick={handleCallClick} data-testid="propertyBoxCall">
                      <FontAwesomeIcon icon={faPhone} size={22} />
                      Call
                    </button>
                  )}

                  {canEmail && (
                    <button type="button" onClick={handleEmailClick} data-testid="propertyBoxEmail">
                      <FontAwesomeIcon icon={faEnvelope} size={22} style={{ marginTop: 1 }} />
                      Email
                    </button>
                  )}
                </BottomRow>
              </TextBoxBottom>
            )}

            {!canCall && !canEmail && accountLogos.length > 0 && (
              <DevelopmentBoxFiller $useWideDisplay={useWideDisplay} />
            )}

            <OptionsRow $useWideDisplay={useWideDisplay}>
              {canHide && (
                <PropertyHide
                  hidden={property.hidden}
                  onHideToggle={(hidden) => props.onHideToggle!()(hidden, property.id)}
                  // unique key needed in infinite grid, once item above is removed from list
                  // it would inherit its hidden state value in PropertyHide
                  key={`hide-${property.id}`}
                  showLabel
                />
              )}
              {canFavourite && (
                <PropertyFavourite
                  favourited={property.shortlisted}
                  // @ts-ignore
                  onFavourite={(favourited) => props.onFavourite()(favourited, property.id)}
                  // unique key needed in infinite grid, once item above is removed from list
                  // it would inherit its favourited state value in PropertyFavourite
                  key={property.id}
                  showLabel
                  containerStyle={{ marginLeft: 10 }}
                />
              )}
            </OptionsRow>

            <AccountLogos $useWideDisplay={useWideDisplay} $enhancedBranding={property.enhancedBranding}>
              {property.enhancedBranding && accountLogos.length === 1 && (
                <AccountLogo
                  key={accountLogos[0].url}
                  style={{
                    backgroundColor: accountLogos[0].backgroundColour,
                    backgroundImage: `url(${accountLogos[0].url})`,
                  }}
                />
              )}

              {!property.enhancedBranding && isDevelopment && accountLogos.length > 0 && (
                <DeveloperLogos $useWideDisplay={useWideDisplay}>
                  {hasDeveloperLogo && <img src={accountLogos[0].url} alt="" />}

                  <div>
                    <p>Selling Agents</p>
                    <SellingAgentLogos $useWideDisplay={useWideDisplay}>
                      {accountLogos.map((logo, i) => {
                        if (i === 0 && hasDeveloperLogo) return null;

                        return <img key={logo.url} src={logo.url} alt="" />;
                      })}
                    </SellingAgentLogos>
                  </div>
                </DeveloperLogos>
              )}

              {!property.enhancedBranding &&
                !isDevelopment &&
                accountLogos.map((logo) => <img src={logo.url} alt="" />)}
            </AccountLogos>
          </TextBox>
        </Content>
      </LinkContainer>
    </Container>
  );
};

export default React.memo(PropertyBox);
