import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { closeTooltip, openTooltip } from '@propertypal/web-ui/src/notifications/Tooltip';
import React, { CSSProperties, FunctionComponent, MouseEventHandler, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Container } from './PropertyFavourite.style';
import { HideBox, HideBtnText } from './PropertyHide.style';

/* istanbul ignore next */
const DELAY = process?.env?.JEST_WORKER_ID ? 0 : 1000;

interface Props {
  containerStyle?: CSSProperties;
  hidden: boolean;
  onHideToggle: (hidden: boolean) => Promise<boolean>;
  innerRef?: React.RefObject<HTMLButtonElement>;
  button?: boolean;
  showLabel?: boolean;
}

const PropertyHide: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [playState, setPlayState] = useState<'running' | 'paused'>('paused');
  const [hidden, setHidden] = useState<boolean>(props.hidden);

  const handleClick: MouseEventHandler = async (e) => {
    e.preventDefault();
    closeTooltip();
    setHidden(!hidden);
    setPlayState('running');

    setTimeout(() => {
      setPlayState('paused');
    }, DELAY);

    const loggedIn = await props.onHideToggle(!hidden);

    if (!loggedIn) {
      setHidden(false);
    }
  };

  useEffect(() => {
    if (props.hidden !== hidden) {
      setHidden(props.hidden);
    }
  }, [props.hidden]);

  return (
    <Container
      ref={props.innerRef}
      style={props.containerStyle}
      onClick={handleClick}
      onMouseEnter={(e) => {
        openTooltip(`${hidden ? 'Show' : 'Hide'} this property in search results.`, e);
      }}
      onMouseLeave={() => {
        closeTooltip();
      }}
      tabIndex={-1}
      className="hide-print pp-favourite-wrapper"
      data-testid="property-hidden-button"
      type="button"
      aria-label="Hide property"
    >
      <HideBox playState={playState} $button={!!props.button}>
        <FontAwesomeIcon
          icon={faEyeSlash}
          size={props.button ? 18 : 22}
          className="pp-hidden-icon"
          color={hidden ? theme.primary : undefined}
          style={{ marginTop: !props.button ? 8 : undefined }}
        />
        {props.button && <HideBtnText>Unhide</HideBtnText>}
      </HideBox>

      {props.showLabel && <span>Hide</span>}
    </Container>
  );
};

export default PropertyHide;
