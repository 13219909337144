import { Agent, Property } from '../../types/property';

export const findAgent = (property: Property, accountNumber: string) => {
  if (property.contacts?.all) {
    return property.contacts.all.find((a) => a.accountNumber === accountNumber);
  }

  if (property.agents) {
    return property.agents.find((a) => a.accountNumber === accountNumber);
  }

  return null;
};

const getSellingAgent = (property: Property): Agent | undefined => {
  if (property.contacts?.default) {
    return {
      ...property.contacts.default,
      logo: property.contacts.default.logos,
      officePhone: property.contacts.default.phoneNumber,
    };
  }

  const agents = property.contacts?.all.filter((c) => !c.developer);

  if (agents?.length === 1) {
    return {
      ...agents[0],
      logo: agents[0].logos,
      officePhone: agents[0].phoneNumber,
    };
  }

  if (property.agents?.length === 1) {
    return property.agents[0];
  }

  return undefined;
};

export const getAgentColours = (enhancedBranding: boolean, agent?: Agent) => {
  if (enhancedBranding && agent?.colours) {
    return agent.colours;
  }

  return {
    pdpBrandingBgColour: '',
    pdpBrandingBorderColour: '',
    pdpBrandingButtonBgColour: '',
    pdpBrandingButtonTextColour: '',
    pdpBrandingLinkColour: '',
    srpBrandingBgColour: '',
    srpBrandingTextColour: '',
  };
};

export default getSellingAgent;
