import { largeTabletMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import Link from 'next/link';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  grid-column: 1 / span 3;

  @media (${largeTabletMax}) {
    grid-column: 1 / span 2;
  }

  @media (${mobileXXLargeMax}) {
    grid-column: 1 / span 1;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.white};
  }

  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.white};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.primary};
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 32px;
  }
`;

export const LinkContainer = styled(Link)<{ $borderColor?: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 3px solid ${({ $borderColor }) => $borderColor};
`;

export const Content = styled.div`
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ImageContent = styled.div`
  position: relative;
  overflow: hidden;
`;

export const TextBox = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;
`;

export const TextContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const TextBoxMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  gap: 15px;
`;

export const TextBoxSide = styled.div`
  display: flex;
  flex: 0 0 60px;
  max-width: 60px;
  flex-direction: column-reverse;
  align-items: flex-end;
  margin-bottom: -10px;

  @media (${mobileXXLargeMax}) {
    flex-direction: column;
    margin-top: -8px;
    margin-bottom: 0;
    max-width: 0;
  }
`;

export const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
  }
`;

export const SummaryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: row;

  & > svg {
    color: ${({ theme }) => theme.textLight};
    margin: 0 10px 0 0;
  }

  & > p {
    margin: 0 20px 0 0;
  }
`;

export const ThumbImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0;
`;

export const ThumbImage = styled.div`
  width: 32%;
`;

export const ExtraDescription = styled.p<{ $color: string }>`
  background-color: ${({ theme }) => theme.backgroundLighter};
  font-weight: bold;
  display: inline-block;
  padding: 4px 6px;
  margin: 20px 0;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ $color }) => $color};
  text-transform: uppercase;
`;

export const AgentLogo = styled.img`
  max-width: 31%;
  max-height: 42px;
  width: auto;
  height: auto;
`;

export const Footer = styled.div<{ color?: string }>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 70px;
  background-color: ${(props) => props.color || props.theme.primary};
  gap: 15px;
`;
