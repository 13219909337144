import styled from 'styled-components';

export const SliderImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default SliderImage;
