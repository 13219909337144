import { Property } from '@propertypal/shared/src/types/property';
import puralise from '@propertypal/shared/src/utils/puralise';
import React, { CSSProperties } from 'react';
import { BadgeText, Container, Placeholder } from './PropertyBadge.style';

interface Props {
  property: Property;
  containerStyle?: CSSProperties;
  showAuctionTime?: boolean;
  useWideDisplay?: boolean;
}

const PropertyBadge = (props: Props) => {
  const { listingTime, totalAvailableUnits } = props.property;

  if (!props.showAuctionTime && listingTime?.type === 'auction') {
    return <Placeholder $useWideDisplay={props.useWideDisplay} />;
  }

  if (totalAvailableUnits !== undefined && totalAvailableUnits > 0) {
    return (
      <Container className="pp-property-box-badge" style={props.containerStyle}>
        <BadgeText data-testid="badgeText">
          {`${totalAvailableUnits} ${puralise('home', 'homes', totalAvailableUnits)} available`}
        </BadgeText>
      </Container>
    );
  }

  if (listingTime && listingTime.typeText && listingTime.timeText && listingTime.type) {
    return (
      <Container
        className="pp-property-box-badge"
        useSecondaryColor={listingTime.type === 'auction'}
        style={props.containerStyle}
      >
        <BadgeText data-testid="badgeText">
          {listingTime.typeText} {listingTime.timeText}
        </BadgeText>
      </Container>
    );
  }

  return <Placeholder $useWideDisplay={props.useWideDisplay} />;
};

export default PropertyBadge;
