import { faHeart as faHeartLight } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart';
import React, { CSSProperties, FunctionComponent, MouseEventHandler, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Container, FavouriteBox, Item } from './PropertyFavourite.style';

interface Props {
  containerStyle?: CSSProperties;
  favourited: boolean;
  onFavourite: (favourited: boolean) => Promise<boolean>;
  innerRef?: React.RefObject<HTMLButtonElement>;
  showLabel?: boolean;
  showBackground?: boolean;
  size?: number;
}

const PropertyFavourite: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const [playState, setPlayState] = useState<'running' | 'paused'>('paused');
  const [favourited, setFavourited] = useState<boolean>(props.favourited);
  const size = props.size || 22;

  const handleClick: MouseEventHandler = async (e) => {
    e.preventDefault();
    setFavourited(!favourited);
    setPlayState('running');

    setTimeout(() => {
      setPlayState('paused');
    }, 1000);

    const loggedIn = await props.onFavourite(!favourited);

    if (!loggedIn) {
      setFavourited(false);
    }
  };

  useEffect(() => {
    if (props.favourited !== favourited) {
      setFavourited(props.favourited);
    }
  }, [props.favourited]);

  return (
    <Container
      style={props.containerStyle}
      ref={props.innerRef}
      type="button"
      onClick={handleClick}
      tabIndex={-1}
      aria-label="Favourite property"
      className="hide-print pp-favourite-wrapper"
      data-testid="property-favourite-button"
      $showBackground={props.showBackground}
    >
      <FavouriteBox playState={playState}>
        <Item opacity={favourited ? 0 : 1} size={size}>
          <FontAwesomeIcon icon={faHeartLight} size={size} className="pp-favourite-icon" />
        </Item>

        <Item opacity={favourited ? 1 : 0} size={size} data-testid="favourite-icon">
          <FontAwesomeIcon
            icon={faHeartSolid}
            size={size}
            style={{ color: theme.primary }}
            className="pp-favourite-icon"
          />
        </Item>
      </FavouriteBox>

      {props.showLabel && <span style={{ color: favourited ? theme.primary : undefined }}>Save</span>}
    </Container>
  );
};

export default PropertyFavourite;
