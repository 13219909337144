import styled, { css } from 'styled-components';
import { Text } from '../typography';
import { FavouriteBox } from './PropertyFavourite.style';

export const HideBtnText = styled(Text).attrs({
  as: 'span',
})`
  font-size: 14px;
  transition: color 300ms linear;
  font-weight: 600;
`;

export const HideBox = styled(FavouriteBox)<{ $button: boolean }>`
  ${({ $button, theme }) =>
    $button
      ? css`
          display: flex;
          position: initial;
          align-items: center;
          gap: 5px;
          width: auto;
          height: auto;
          border-radius: 8px;
          border: 1px solid ${theme.keyline};
          padding: 8px;
          transition: border-color 300ms linear;

          &:hover {
            border-color: ${theme.primary};
          }
        `
      : ''}
`;
